import { render, staticRenderFns } from "./hadGetDetailMsgScreen.vue?vue&type=template&id=56c07045&scoped=true&"
import script from "./hadGetDetailMsgScreen.vue?vue&type=script&lang=js&"
export * from "./hadGetDetailMsgScreen.vue?vue&type=script&lang=js&"
import style0 from "./hadGetDetailMsgScreen.vue?vue&type=style&index=0&id=56c07045&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c07045",
  null
  
)

export default component.exports