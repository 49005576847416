<template>
  <div class="wish-detail" v-if="showCard">
    <!-- <van-nav-bar title="认领心愿" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="img">
        <img :src="detailData.materialId" alt />
      </div>
      <h3>
        {{detailData.wishTitle}}
        <div
          v-if="detailData.wishStatus===3"
          class="baomingButton backRed"
          @click="showQrcode=true"
        >心愿完成</div>
        <div
          v-if="detailData.wishStatus===4"
          class="baomingButton backRed"
          @click="showQrcode=true"
        >已完成</div>
      </h3>
      <div class="post-info">
        <p>
          许愿人：{{detailData.wishPersonName}}
          <img
            src="./../../assets/img/contact.png"
            alt
            v-if="detailData.wishPersonMobile"
            @click.stop="openCallBox(detailData.wishPersonMobile,1)"
          />
          <!-- 许愿人是自己不显示  && 认领人不是自己不显示-->
        </p>
        <p>发布时间：{{detailData.startTime}}</p>
        <p>截至时间：{{detailData.endTime}}</p>
      </div>
      <div class="wish-content">
        <h5>心愿内容</h5>
        <div class="content">{{detailData.description}}</div>
      </div>
      <div class="checked-info">
        <h5>认领信息</h5>
        <p>
          认领人：{{detailData.givePersonnelName}}
          <img
            src="./../../assets/img/contact.png"
            alt
            v-if="detailData.givePersonnelMobile"
            @click.stop="openCallBox(detailData.givePersonnelMobile,2)"
          />
        </p>
        <p>领办时间：{{detailData.giveAt}}</p>
        <p>备注：{{detailData.feedback}}</p>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop="show=false">
        <div class="block">
          <h3>{{flag===1?'许愿人的电话':'认领人的电话'}}</h3>
          <p class="call">{{phone}}</p>
          <!-- <p class="is">
            <span @click.stop="toCall">是</span>
            <span @click.stop="show=false">否</span>
          </p>-->
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showQrcode">
      <div class="wrapper" @click.stop="showQrcode=false">
        <div class="block">
          <h3>温馨提示</h3>
          <p class="call">是否心愿已经完成？</p>
          <p class="is">
            <span @click.stop="toComplete">是</span>
            <span @click.stop="showQrcode=false">否</span>
          </p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { detail } from '@/api/tinyWish';
import { screenTinyWishComplete, screenTinyWishDetail } from '@/api/bigScreen';
import { Notify } from 'vant';
export default {
  data() {
    return {
      detailData: {},
      realName: '',
      showCard: false,
      show: false,
      showQrcode: false,
      phone: '',
      flag: 1
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //打开与他联系框
    openCallBox(phone, flag) {
      this.flag = flag;
      this.show = true;
      this.phone = phone;
    },
    //与他联系
    toCall() {
      dd.biz.telephone.showCallMenu({
        phoneNumber: this.phone, // 期望拨打的电话号码
        code: '+86', // 国家代号，中国是+86
        showDingCall: true, // 是否显示钉钉电话
        onSuccess: function() {
          console.log('成功');
        },
        onFail: function() {
          console.log('失败');
        }
      });
    },
    // 心愿完成
    toComplete() {
      screenTinyWishComplete({
        id: this.detailData.id
      })
        .then(res => {
          if (res.success) {
            Notify({ type: 'success', message: '操作成功' });
            // setTimeout(() => {
            //   this.$router.go(-1);
            // }, 500);
          } else {
            Notify({ type: 'success', message: '操作失败' });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    // this.realName = JSON.parse(localStorage.getItem('user')).realName;
    screenTinyWishDetail({
      id: this.$route.query.id
    })
      .then(res => {
        this.detailData = res.data;
        this.showCard = true;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    background: #f3f3f3;
    flex: 1;
    .img {
      width: 750 * $px;
      height: 320 * $px;
      background: url('./../../assets/img/list.png') center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      background: #fff;
      font-size: 32 * $px;
      position: relative;
      font-family: PingFang SC;
      font-weight: 600;
      color: rgba(34, 34, 34, 1);
      height: 48 * $px;
      line-height: 48 * $px;
      padding: 24 * $px 0 24 * $px 24 * $px;
      border-bottom: 2 * $px solid #f3f3f3;
    }
    .post-info {
      background: #fff;
      padding: 24 * $px 0 24 * $px 24 * $px;
      font-size: 24 * $px;
      color: rgba(85, 85, 85, 1);
      p:nth-of-type(2) {
        margin: 14 * $px 0;
      }
      img {
        width: 132 * $px;
        height: 30 * $px;
      }
    }
    .wish-content {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      .content {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
      }
    }
    .checked-info {
      margin-top: 24 * $px;
      background: #fff;
      padding: 27 * $px 24 * $px;
      h5 {
        font-size: 32 * $px;
        font-weight: 600;
        color: rgba(34, 34, 34, 1);
        margin-bottom: 24 * $px;
      }
      p {
        font-size: 28 * $px;
        color: rgba(34, 34, 34, 1);
        &:nth-of-type(2) {
          margin: 10 * $px 0;
        }
        img {
          width: 132 * $px;
          height: 30 * $px;
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 600 * $px;
      height: 300 * $px;
      background-color: #fff;
      border-radius: 8 * $px;
      padding: 30 * $px;
      box-sizing: border-box;
      h3 {
        font-size: 32 * $px;
        padding-left: 20 * $px;
      }
      p {
        font-size: 34 * $px;
        &.call {
          margin: 40 * $px;
          margin-left: 20 * $px;
          text-align: center;
        }
        &.is {
          margin: 10 * $px 0 0 280 * $px;
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}
.baomingButton {
  width: 120 * $px;
  height: 40 * $px;
  line-height: 40 * $px;
  text-align: center;
  position: absolute;
  bottom: 30 * $px;
  right: 0 * $px;
  border-radius: 24 * $px;
  border: #ff192f 1 * $px solid;
  //   box-sizing: border-box;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  font-size: 24 * $px;
  margin-right: 20 * $px;
  margin-left: 20 * $px;
}
.backRed {
  background-color: #ff192f;
  color: #ffffff;
}
</style>